
.ourServices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-color: white;

}

.services_heading {
    padding-top: 100px;
    font-family: "inter";
    font-weight: 700;
    font-size: 4rem;
}

.services__desc {
    font-size: 2rem;
}

.cardContainer {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: auto;

}

.cardHolder {
    margin-left: 30px;
    margin-right: 30px;
}



@media (max-width : 480px) {
    .services_heading {
        font-size: 2.8rem;
    }

    .services__desc {
        font-size: 1.5rem;
    }

    .cardContainer {
        grid-template-columns: repeat(1, 1fr);
    }

}