

/* Banner CSS */
#carouselExampleControls,
.carousel-inner {
  height: 100vh;
}


@media(max-width:480px) {

  .carousel-inner, #carouselExampleControls {
    height: 25vh;
  }
}

