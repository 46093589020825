
.NavLogo {
    height: 60px;
    width: auto;
}

.navbar {
    color: white;
    font-weight: 700;
    background-color: #001D6E;
}

.nav-link {
    color: white !important;
}

@media (max-width : 480px) {
    .navbar-toggler-icon {
        color: white !important;
    }
    .navbar-toggler {
        color : white !important;
    }
    .collapsed {
        color: white;
    }
}