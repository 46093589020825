

/* 212529 */
.mainFooter{
    background-color: #212529;
    color: white;
}

.upperSocial {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    border-bottom: grey 1px solid;
}


.leftupperName h4 {
    font-size: 1rem;
}


.downFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    height: 40vh;
}

.Footerlogo {
    height: 100px;
    width: auto;
}

.Copyright_div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    border-top: 1px solid grey;
    padding: 20px;
}

@media (max-width : 480px) {

    .upperSocial {
        flex-direction: column;
        padding: 30px;
    }

    .Footerlogo {
        display: none;
    }

    .downFooter {
        width: 100vw;
        overflow : hidden;
    }

    .Copyright_div {
        flex-direction: column;
    }
}