/* importing google fonts  */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800&family=Raleway:wght@200;400;600;700&display=swap');

* {
    margin: 0px;
    padding: 0px;
    font-family: "raleway";
}

h1, h2 {
    font-family: "inter";
    color: #001D6E;
}

hr {

    height: 10px;
    color: black;
    width: 70vw;

    overflow: hidden;
}

.App {
    background-color: #DFF6FF;
}