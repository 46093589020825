
.CalltoAction {
    
    width: 100%;
    height: 90vh;    

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-image:linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
    background-size: cover;

}

.cta_title { 
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    color: yellow;
}



@media (max-width : 480px) {
    .CalltoAction {
        height: 50vh;
    }

     .cta_title {
         font-size: 1.8rem;
     }
}
