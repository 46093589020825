
.card {
    margin: auto;
    margin-bottom: 40px;
    background-color: white;

    box-shadow: rgba(0,0,0,0.3), 10px, 20px, 20px, 10px;

}

.card-body {

    margin: 20px;
    background-color:white !important;
    text-align: left;
}


.cardTitle {
    color: #001D6E;
    font-size: 2.25rem;
    font-family: "inter";
    font-weight: 700;
}



.card-img-top {
    height: 300px;
    border-radius: 0px;
}
