.Gallery {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
}

.galleryHeading h1 {
  font-size: 4rem;
  font-weight: 700;
  padding-top: 80px;
}

.galleryHeading {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 50px;
}

.photos {
  /* display: grid;
    grid-template-columns: repeat(3,1fr);
    place-items: center; */

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.photos img {
  width: 25%;
  height: auto;
  padding: 30px;
}

/* =============================== Lightbox Code ====================================== */

.lightbox {
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.8);

  position: fixed;
  top: 0px;
  left: 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lightbox img {
  height: 80vh;
  width: auto;
}

.lightBoxCross {
  position: fixed;
  top: 30px;
  right: 30px;
  color: white;
}
.lightBoxLeft {
    position: fixed;
    top: 50%;
    left: 50px;
    color : White;
    font-size: 2rem;
}

.LightBoxRight {
    position: fixed;
    top: 50%;
    right: 50px;
    color: white;
    font-size: 2rem;
}

@media (max-width: 480px) {
  .photos img {
    width: 50%;
  }

  .lightbox img {
    height: 50vh;
  }
}
