

.textContainerOuter {
    padding: 80px;
}


.textContainer {
    display: flex;
    flex-direction: row;

    background-color: white;
    padding: 30px;
    box-shadow: rgba(0,0,0,0.4) 10px 20px 10px 10px ;
}

.leftContainer {
    width:40%;

}

.leftContainer img {
    width: 100%;
}

.rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;


    width: 60%;
    padding: 20px;

    overflow: hidden;
}

.text__title {
    font-family: "inter";
    font-weight: 700;
    font-size: 2.5rem;
}

.text__desc {
    font-size: 1.2rem;
    font-weight: 500;
}


@media (max-width : 480px) {
    .textContainerOuter {
        width: 100vw;
        padding: 5px;
    }

    .textContainer {
        flex-direction: column !important;
    }

    .rightContainer {
        width: 100% !important;
    }

    .leftContainer {
        width: 100% !important;

    }
    .text__title {
        font-size: 1.9rem;
    }
}