

.ServiceContainer {

        display: flex;
        flex-direction: row;

        background-color: white;
        width: 80vw;

        margin: 50px;
        



}

.leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    width: 50%;
    height: 500px;
}

.leftContainer img {
    width: 100%;
    height: 500px;
}


.SerH1, .SerDesc{
    width: 100%;
}

.SerDesc {
    font-size: 1.2rem;
    font-weight: 600;
}

.SerH1 {
    font-weight: 700;
    font-size: 4rem;
}


.rightContainer {
    width: 50%;
    padding: 20px;
}


@media (max-width : 480px) {
    .ServiceContainer {
        flex-direction: column !important;
    }

    .leftContainer {
        height: 400px;
    }
    .SerH1 {
        font-size: 3rem;
    }
}