

@media (max-width : 480px) {
    .FAQh1 {
        font-size: 2rem;
        padding-top: 20px;
    }

    .faqSubHeading {
        font-size: 1.2rem;
    }

    .SingleFAQDiv {
        margin:0px !important;
        padding: 0px !important;

        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
}