

.SinglepageBackground {
    background-size: cover;
    width: 100%;
    height: 90vh;
}

.hello {
    background-image: linear-gradient();
}

.SinglePageHeading {
    position: absolute;
    right: 30px;
    bottom: 30px;

    font-size: 5rem;
    font-weight: 800;
    text-decoration:underline;
}

@media (max-width : 480px) {
        .SinglepageBackground {
            height: 50vh;
        }

        .SinglePageHeading {
            position: absolute;
            top: 38%;
            right: 10px;

            
        }
        }