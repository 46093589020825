

.CertificationDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: white;
    padding: 100px;

    margin-bottom: 50px;
}

.CertificationDiv h1 {
    font-size: 3rem;
    font-weight: 700;
}

.CertificationContainer {
    display: flex;
    flex-direction: row;

    justify-content: space-evenly;
    align-items: center;

    width: 100vw;

    padding-top:20px ;

}

.Seal img{
    width: 250px;
    height: 250px;
}

@media (max-width : 480px) {
    .CertificationDiv {
        padding: 20px;
    }

    .CertificationContainer {
        flex-direction: column;
    }

    .CertificationDiv h1 {
        font-size: 2rem;
        text-align: center;
    }
}