
.AboutMain {
    display: flex;

    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    background-image: linear-gradient(rgba(255,255,255,0.9),rgba(255,255,255,0.4)), url("https://media.istockphoto.com/photos/wrenches-angle-and-tconnector-picture-id636821270?k=20&m=636821270&s=612x612&w=0&h=_KUhBnT7PuMnG4dA7E6-95rR173hlM1aoVH1SKF3xqo=");
    background-size: cover;


    height: 90vh;
    width: 100%;
}

.topAbout h1 {
    font-size: 5rem;
    font-weight: 700;
    text-align: center;

}

.btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
} 

.btnContainer a {
    font-size: 1.5rem;
    margin: 40px;
}

/* Middle Div */

.middleAbout {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}


/* Faq */

.faqHeadingDiv {

    padding: 50px;
    padding-top: 100px;
}

.FAQh1 {
    font-weight: 700;
    font-size: 4.5rem;
    text-align: center;
    align-self: center;
}

.faqSubHeading {
    text-align: center;
}

/* Form */

.FormContainer {
    padding-top: 100px;

}

.GoogleMap {
    padding: 30px;
    width: 100%;
}

.MapHeading {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    padding-top: 50px;
}

@media(max-width :480px) {

    .AboutMain {
        height: 40vh;
    }

    .topAbout h1 {
        font-size: 3rem;
        padding-bottom: 30px;
    }

    .btnContainer {
        flex-direction: column;
        padding: 0px;
        margin: 0px;
    }

    .btnContainer a {
        margin: 10px;
    }

    .MapHeading {
        font-size: 2rem;
    }
}