
.ServiceHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 50px;
}

.ourServiceHeading, .ourServicesSubHeading {
    
    text-align: center;

}

.ourServiceHeading {
    padding-top: 100px;
    font-weight: 700;
    font-size: 5rem;
}

@media (max-width : 480px) {
    .ourServiceHeading {
        font-size: 2.5rem;
    }

    
}