

.Details {
    display: flex;
    flex-direction: row;

    height: 600px;
    background-color: white;
    /* color: black; */
}

.leftDetails {

    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 1rem;
}

.leftDetails h1 {
    font-size: 4rem;
    position: relative;
    bottom: 50px;
}

.leftDetails p {
    font-size: 2rem;
}

.rightDetails {
    width: 50%;
}

.rightDetails img {
    width: 100%;
}

@media (max-width : 480px) {
    .Details {
        width: 100vw;
        height: 60vh;

        flex-direction: column-reverse;
    }

    .leftDetails {
        width: 100%;
    }

    .rightDetails {
        width: 100%;
    }

    .leftDetails h1 {
        font-size: 2rem;
        font-weight: 700;
        position: static;
        padding: 20px;
    }
    .leftDetails p {
        font-size: 1.2rem;
    }
}