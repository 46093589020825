

@media (max-width : 480px) {
    .FAQh1 {
        font-size: 2rem;
    }

    .faqSubHeading {
        font-size: 1.2rem;
    }
}