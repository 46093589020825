


.Hero__background {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    /* Background */
    background-image: linear-gradient(rgba(255,255,255,0.4), rgba(255,255,255,0.4)),  url("https://images.unsplash.com/photo-1558618666-fcd25c85cd64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80");
    background-size: cover;

    /* Dimensions */
    height: 90vh;   
    width: 100%;
}

.Hero__title {
    font-family: "inter";
    font-weight: 800;
    font-size: 6rem;
    color: #001D6E;
}

.Hero__description {
    font-weight: 600;
    font-size: 2rem;
}

.Hero__buttonContainer {
    display: flex;
    flex-direction: row;
}

.Hero__button {
        margin-top: 30px;
        font-size: 1.5rem;
        margin-right: 30px;
        margin-left: 30px;
        padding: 10px;


        font-family:  "inter";
        font-weight: 700;
        

}


/* Tablet View */
/* @media (max-width: 991px) {

} */

@media (max-width : 480px) {
    .Hero__background {
        height: 40vh;
        width: 100vw;
    }

    .Hero__title {
        font-size: 2.2rem;
    }

    .Hero__description {
        font-size: 1rem;
    }

    .Hero__buttonContainer {
        flex-direction: column;
    }
    .Hero__button {

        font-size: 0.8rem;
        margin: 0px;
        padding: 10px;
        margin: 10px;
    }
}